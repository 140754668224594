<template>
    <div>
        <p><span style="color: green; margin-right: 20px;">主机监控补货通知:</span><a target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=qbDlO4xz46K2vKG0iLgulJgcPRMyh6AN&jump_from=webapi"><img border="0" style= "margin-bottom: -5px" :src="qq_icon"  title="780813483"/>780813483</a>｜<a href="https://t.me/hostmonit" target="_blank"><img style= "margin-bottom: -5px;" title="@hostmonit" :src="telegram_icon"/></a><a href="https://t.me/hostmonit" target="_blank"> @hostmonit</a></p>
        <el-table
                :data="tableData"
                :row-class-name="tableRowClassName"
                v-loading="loading"
                style="width: 81%; margin: 0 auto"
        >
            <el-table-column
                    prop="flag"
                    label="Title"
                    sortable
                    width="160">
            </el-table-column>
            <el-table-column
                    prop="cpu"
                    label="CPU"
                    sortable
                    width="110"
                    :formatter="cpuFormatter">
            </el-table-column>
            <el-table-column
                    prop="ram"
                    label="RAM"
                    sortable
                    width="110"
                    :formatter="armFormatter">
            </el-table-column>
            <el-table-column
                    prop="disk"
                    label="Disk"
                    sortable
                    width="100"
                    :formatter="diskFormatter">
            </el-table-column>
            <el-table-column
                    prop="ip"
                    label="IP"
                    sortable
                    width="110"
                    :formatter="ipFormatter">
            </el-table-column>
            <el-table-column
                    prop="location"
                    label="Location"
                    sortable
                    width="160">
            </el-table-column>
            <el-table-column
                    prop="bandwidth"
                    label="Bandwidth"
                    sortable
                    width="150">
            </el-table-column>
            <el-table-column
                    prop="price"
                    label="Price(/Year)"
                    sortable
                    width="150"
                    :formatter="priceFormatter"
            >
            </el-table-column>
<!--            <el-table-column
                    prop="eprice"
                    label="Valuation(/Year)"
                    sortable
                    width="150"
                    :formatter="epriceFormatter"
            >
            </el-table-column>
            <el-table-column
                    prop="estimate"
                    label="Cost-effective"
                    sortable
                    width="150">
            </el-table-column>-->
            <el-table-column
                    prop="status"
                    label="Available"
                    :sort-by="['status', 'estimate']"
                    sortable
                    width="120">
                    <template slot-scope="scope">
                        <el-tag
                                :type="scope.row.status === 1 ? 'success' : 'info'"
                                disable-transitions>{{formatStatus(scope.row.status)}}</el-tag>
                    </template>
            </el-table-column>
            <el-table-column
                    prop="remark"
                    label="Remark"
                    width="170">
                    <template slot-scope="scope">
                        <el-tag
                                :type="scope.row.remark === 'best' ? 'danger' : scope.row.remark === 'great' ? 'warning' : scope.row.remark === 'good' ? 'success' : scope.row.remark === 'average' ? 'primary' : 'info'"
                                disable-transitions>{{formatRemark(scope.row.remark)}}</el-tag>
                    </template>
            </el-table-column>
            <el-table-column label="Operate" width="100">
                <template slot-scope="scope">
                    <el-button v-if='scope.row.status === 1' size="mini" type="success"  @click = "buy(scope.row.num, scope.row.token)">Buy</el-button>
                    <el-button v-else-if='scope.row.status === 0' size="mini" type="info"  @click = "buy(scope.row.num, scope.row.token)">View</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pageinfo">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-sizes="[10, 50, 100, 200]"
                    :page-size="pageSize"
                    layout="sizes, prev, pager, next"
                    :total="total">
            </el-pagination>
        </div>
        <el-divider><i class="el-icon-cpu"></i></el-divider>
        <section style="display: inline-block; margin-right: 250px"><a target="_blank" href="https://app.cloudcone.com/?ref=5378"><img alt="CloudCone LOGO" :src="cloudcone"></a></section>
        <section style="display: inline-block">
            <h2><a id="title" href="http://la.lg.cloudc.one" target="_blank">CloudCone Looking Glass</a></h2>
            <div class="row">
                <div class="span12">
                    <div>
                        <span >Network information</span><!-- IE/Safari dislike <legend> out of context -->
                        <p>Server Location: <b>Los Angeles, USA</b></p>
                        <div style="margin-left: 10px;">
                            <p>Test files: <a href="http://la.lg.cloudc.one/100MB.test" target="_blank">100MB</a> <a href="http://la.lg.cloudc.one/500MB.test" target="_blank">500MB</a> <a href="http://la.lg.cloudc.one/1000MB.test" target="_blank">1000MB</a> </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import cloudcone from "@/assets/cloudcone-logo.png"
import qq_icon from "@/assets/qq_icon.png"
import telegram_icon from "@/assets/telegram_icon.png"
    export default {
        name: "cloudcone",
        data(){
            return {
                currentPage: 1,
                pageSize: 10,
                total: 200,
                loading: true,
                tableData: [],
                cloudcone: cloudcone,
                qq_icon: qq_icon,
                telegram_icon: telegram_icon,
            }
        },
        created() {
            this.getCloudConeData(1, 10)
            this.dataRefreh()
        },
        methods: {
            tableRowClassName({ row, rowIndex }) {
                if (row.status >= 1) {
                    return 'active-row'
                }
                return 'active-other-row'
            },
            buy(index, token) {
                console.log(token)
                if(token != null && token != ""){
                    window.open('https://app.cloudcone.com/compute/'+index+'/create?ref=5378&token='+ token,'_blank')
                }else{
                    window.open('https://app.cloudcone.com/compute/'+index+'/create?ref=5378','_blank')
                }
            },
            cpuFormatter(row, column) {
                return row.cpu + ' vCPU'
            },
            armFormatter(row, column) {
                return row.ram + ' MB'
            },
            diskFormatter(row, column) {
                return row.disk + ' GB'
            },
            ipFormatter(row, column) {
                return row.ip + ' IPv4'
            },
            priceFormatter(row, column) {
                return '$'+row.price
            },
            epriceFormatter(row, column) {
                return '$'+row.eprice
            },
            formatStatus(status) {
                if(status === 1){
                    return "YES"
                }else{
                    return "NO"
                }
            },
            formatRemark(remark) {
                switch(remark){
                    case 'best':
                        return '惊人折扣，快抢！'
                    case 'great':
                        return '绝对超值！'
                    case 'good':
                        return '超值！'
                    case 'average':
                        return '还行。'
                    default:
                        return '一般般的啦！'
                }
            },
            getCloudConeData(pageNo, pageSize){
                const _this = this
                _this.loading = true
                let params = {"pageNo":pageNo,"pageSize":pageSize}
                axios.post('https://backend.stock.hostmonit.com/cloudcone_monitor/findAllCloudCone', params).then(function (resp) {
                    _this.tableData = resp.data.content
                    _this.total = resp.data.totalElements
                    _this.loading = false
                })
            },
            handleSizeChange(val) {
                this.pageSize = val
                this.getCloudConeData(1, this.pageSize)
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.getCloudConeData(this.currentPage, this.pageSize)
            },
            // 定时刷新数据函数
            dataRefreh() {
                // 计时器正在进行中，退出函数
                if (this.intervalId != null) {
                return;
                }
                // 计时器为空，操作
                this.intervalId = setInterval(() => {
                console.log("刷新" + new Date());
                this.getCloudConeData(this.currentPage, this.pageSize); //加载数据函数
                }, 60000);
            }, 
            // 停止定时器
            clear() {
                clearInterval(this.intervalId); //清除计时器
                this.intervalId = null; //设置为null
            },
        },
        destroyed() {
            // 在页面销毁后，清除计时器
            this.clear();
        }
    }
</script>
<style type='text/css'>
</style>
<style scoped>
    .pageinfo {
        padding-top: 20px;
        padding-bottom: 20px;
    }
</style>