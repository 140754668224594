import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Monitor from '../views/Monitor.vue'
import CloudCone from '../views/CloudCone.vue'
import cloudconeVps from '../views/CloudConeVps.vue'
import BWH from '../views/BandwagonHost.vue'
import DMIT from '../views/Dmit.vue'
import XTOM from '../views/Xtom.vue'
import RackNerd from '../views/RackNerd.vue'
import AlphaVps from '../views/AlphaVps.vue'
import virSO from '../views/virmachSpecials.vue'
import Vir18bf from '../views/VirMach18BF.vue'
import Vir19bf from '../views/VirMach19BF.vue'
import Vir20bf from '../views/VirMach20BF.vue'
import Vir21bf from '../views/VirMach21BF.vue'
import Vir22bf from '../views/VirMach22BF.vue'
import spartanhostSDS from '../views/spartanhostSDS.vue'
import spartanhostPKS from '../views/spartanhostPKS.vue'
import spartanhostE3S from '../views/spartanhostE3S.vue'
import spartanhostE5S from '../views/spartanhostE5S.vue'
import rcp from '../views/rcp.vue'
import buyvmLV from '../views/buyvmLV.vue'
import buyvmLU from '../views/buyvmLU.vue'
import buyvmMIA from '../views/buyvmMIA.vue'
import buyvmNY from '../views/buyvmNY.vue'
import CFYes from '../views/CFYes.vue'
import CFYesV6 from '../views/CFYesV6.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Monitor',
    meta: { title: 'Monitor', keywords: 'keyword test', description: 'description test' },
    component: Monitor,
    // redirect: 'Monitor'
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  //  {
  //   path: '/monitor',
  //   component: Monitor,
  //   meta: { title: 'Monitor', keywords: 'keyword test', description: 'description test' }
  //  },
    {
      path: '/cloudcone',
      component: CloudCone,
      meta: { title: 'Cloudcone Compute(SC2)' }
    },
    {
      path: '/cloudconeVps',
      component: cloudconeVps,
      meta: { title: 'Cloudcone Vps' }
    },
    {
      path: '/bandwagonhost',
      component: BWH,
      meta: { title: 'Bandwagon host' }
    },
    {
      path: '/dmit',
      component: DMIT,
      meta: { title: 'DMIT' }
    },
    {
      path: '/xtom',
      component: XTOM,
      meta: { title: 'XTOM' }
    },
    {
      path: '/rackNerd',
      component: RackNerd,
      meta: { title: 'RackNerd' }
    },
    {
      path: '/alphaVps',
      component: AlphaVps,
      meta: { title: 'AlphaVps' }
    },
    {
      path: '/spartanhostSDS',
      component: spartanhostSDS,
      meta: { title: 'SpartanHost Seattle Dedicated Servers' }
    },
    {
      path: '/spartanhostPKS',
      component: spartanhostPKS,
      meta: { title: 'SpartanHost SSD Premium KVM VPS - Seattle' }
    },
    {
      path: '/spartanhostE3S',
      component: spartanhostE3S,
      meta: { title: 'SpartanHost HDD E3 KVM VPS - Seattle' }
    },
    {
      path: '/spartanhostE5S',
      component: spartanhostE5S,
      meta: { title: 'SpartanHost SSD E5 KVM VPS - Seattle' }
    },
    {
      path: '/rcp',
      component: rcp,
      meta: { title: 'SpartanHost SSD E5 KVM VPS - Seattle' }
    },
    {
      path: '/buyvmLV',
      component: buyvmLV,
      meta: { title: 'Las Vegas - KVM & Storage' }
    },
    {
      path: '/buyvmLU',
      component: buyvmLU,
      meta: { title: 'Luxembourg - KVM & Storage' }
    },
    {
      path: '/buyvmNY',
      component: buyvmNY,
      meta: { title: 'New York - KVM & Storage' }
    },
    {
      path: '/buyvmMIA',
      component: buyvmMIA,
      meta: { title: 'Miami - KVM & Storage' }
    },
    {
      path: '/virSO',
      component: virSO,
      meta: { title: 'Virmach Special Offers' }
    },
    {
      path: '/vir18bf',
      component: Vir18bf,
      meta: { title: 'virmach 2018 black friday' }
    },
    {
      path: '/vir19bf',
      component: Vir19bf,
      meta: { title: 'virmach 2019 black friday' }
    },
    {
      path: '/vir20bf',
      component: Vir20bf,
      meta: { title: 'virmach 2020 black friday' }
    },
    {
      path: '/vir21bf',
      component: Vir21bf,
      meta: { title: 'virmach 2021 black friday' }
    },
    {
      path: '/vir22bf',
      component: Vir22bf,
      meta: { title: 'virmach 2022 black friday' }
    },
    {
      path: '/CloudFlareYes',
      component: CFYes,
      meta: { title: 'cloudflareYes' }
    },
    {
      path: '/CloudFlareYesV6',
      component: CFYesV6,
      meta: { title: 'cloudflareYesV6' }
    },
    {
      path: '/home',
      beforeEnter() {location.href = 'https://hostmonit.com'}
    }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 全局配置
// router.beforeEach((to, from, next) => {
//   // Change doc title
//   document.title = to.meta.title || 'Unknow title'
//   document.querySelector('meta[name="keywords"]').setAttribute('content', 'keywords')
//   document.querySelector('meta[name="description"]').setAttribute('content', 'description')
// })

export default router
