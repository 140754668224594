<template>
    <div>
        <!-- Place this tag where you want the button to render. -->
        <div id="app" style="width: 26%; margin: 10px auto">
		<span style="color: orange; margin-right: 20px;">CloudFlare优质IP自动切换: <a target="_blank"  href="https://github.com/ddgth/cf2dns">GitHub</a></span>
        <gh-btns-star style="float: right" slug="ddgth/cf2dns" show-count></gh-btns-star>
		<!-- <gh-btns-watch slug="ddgth/cf2dns" show-count></gh-btns-watch> -->
		<!-- <gh-btns-fork slug="ddgth/cf2dns" show-count></gh-btns-fork> -->
		<!-- <gh-btns-follow user="ddgth" show-count></gh-btns-follow> -->
	    </div>
        <div style="width: 60%; margin: 0 auto">
        <el-table
                :data="tableData"
                :row-class-name="tableRowClassName"
                v-loading="loading"       
        >
            <el-table-column
                    prop="line"
                    label="Line"
                    sortable
                    width="120">
                    <template slot-scope="scope">
                        <el-tag
                                :type="scope.row.line === 'CU' ? 'danger' : scope.row.line === 'CT' ? 'primary' : scope.row.line === 'CM' ? 'success' : 'info'"
                                disable-transitions>{{formatRoute(scope.row.line)}}</el-tag>
                    </template>
            </el-table-column>
            <el-table-column
                    prop="ip"
                    label="IP"
                    sortable
                    width="160">
            </el-table-column>
            <el-table-column
                    prop="latency"
                    label="Latency"
                    sortable
                    width="150"
                    :formatter="latencyFormatter">
            </el-table-column>
            <el-table-column
                    prop="loss"
                    label="Loss"
                    sortable
                    width="150"
                    :formatter="lossFormatter">
            </el-table-column>
            <el-table-column
                    prop="speed"
                    label="Speed"
                    sortable
                    width="160"
                    :formatter="speedFormatter">
            </el-table-column>
            <el-table-column
                    prop="colo"
                    label="Colo"
                    sortable
                    width="150">
            </el-table-column>
            <el-table-column
                    prop="time"
                    label="Time"
                    sortable
                    width="180">
            </el-table-column>
        </el-table>
        <div class="pageinfo">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-sizes="[15]"
                    :page-size="pageSize"
                    layout="sizes, prev, pager, next"
                    :total="total">
            </el-pagination>
        </div>
        <el-divider><i class="el-icon-cpu"></i></el-divider>
        </div>
    </div>
</template>

<script>
export default {
    name: "CFYes",
    data(){
        return {
            currentPage: 1,
            pageSize: 15,
            total: 15,
            loading: true,
            tableData: [],
        }
    },
    created() {
        this.getCFYesData()
    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            return 'active-other-row'
        },
        latencyFormatter(row, column) {
            return row.latency + 'ms'
        },
        lossFormatter(row, column) {
            return row.loss + '%'
        },
        speedFormatter(row, column) {
            return row.speed + ' KB/s'
        },
        formatRoute(route) {
            switch(route){
                case 'CM':
                    return '移动'
                case 'CT':
                    return '电信'
                default:
                    return '联通'
            }
        },
        getCFYesData(){
            const _this = this
            _this.loading = true
            let data = {"key": "iDetkOys", "type": "v6"}
            axios.post('https://api.hostmonit.com/get_optimization_ip',data).then(function (resp) {
                _this.tableData = resp.data.info
                _this.total = resp.data.total
                _this.loading = false
            })
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.getCFYesData(1, this.pageSize)
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getCFYesData(this.currentPage, this.pageSize)
        }
    }
}
</script>
<style type='text/css'>
    .el-table .active-other-row:hover>td {
	    background-color: #F0F9EB !important;
    }
</style>
<style scoped>
    .pageinfo {
        padding-top: 20px;
        padding-bottom: 20px;
    }
</style>