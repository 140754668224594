<template>
    <div>
        <p><span style="color: green; margin-right: 20px;">主机监控补货通知:</span><a target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=qbDlO4xz46K2vKG0iLgulJgcPRMyh6AN&jump_from=webapi"><img border="0" style= "margin-bottom: -5px" :src="qq_icon"  title="780813483"/>780813483</a>｜<a href="https://t.me/hostmonit" target="_blank"><img style= "margin-bottom: -5px;" title="@hostmonit" :src="telegram_icon"/></a><a href="https://t.me/hostmonit" target="_blank"> @hostmonit</a></p>
        <p><a href="https://hostmonit.com/virmach-checpvps/#virmach_speedtest" target="_blank" style="color: red">VirMach各机房测速</a></p>
        <el-table
                :data="tableData"
                :row-class-name="tableRowClassName"
                v-loading="loading"
                style="width: 81%; margin: 0 auto"
        >
            <el-table-column
                    prop="cpu"
                    label="CPU"
                    sortable
                    width="110"
                    :formatter="cpuFormatter">
            </el-table-column>
            <el-table-column
                    prop="ram"
                    label="RAM"
                    sortable
                    width="110"
                    :formatter="armFormatter">
            </el-table-column>
            <el-table-column
                    prop="hdd"
                    label="Disk"
                    sortable
                    width="100"
                    :formatter="diskFormatter">
            </el-table-column>
            <el-table-column
                    prop="ips"
                    label="IPs"
                    sortable
                    width="100"
                    :formatter="ipFormatter">
            </el-table-column>
            <el-table-column
                    prop="location"
                    label="Location"
                    sortable
                    width="160">
            </el-table-column>
            <el-table-column
                    prop="bw"
                    label="Bandwidth"
                    sortable
                    width="140"
                    :formatter="bwFormatter"
            >
            </el-table-column>
            <el-table-column
                    prop="price"
                    label="Price(/Year)"
                    sortable
                    width="130"
                    :formatter="priceFormatter"
            >
            </el-table-column>
            <el-table-column
                    prop="estimate"
                    label="Discount"
                    sortable
                    width="120"
                    :formatter="discountFormatter"
            >
            </el-table-column>
            <el-table-column
                    prop="eprice"
                    label="Valuation(/Year)"
                    sortable
                    width="150"
                    :formatter="epriceFormatter"
            >
            </el-table-column>
            <el-table-column
                    prop="message"
                    label="Message"
                    sortable
                    width="200">
            </el-table-column>
            <el-table-column
                    prop="remark"
                    label="Remark"
                    width="160">
                    <template slot-scope="scope">
                        <el-tag
                                :type="scope.row.remark === 'best' ? 'danger' : scope.row.remark === 'great' ? 'warning' : scope.row.remark === 'good' ? 'success' : scope.row.remark === 'average' ? 'primary' : 'info'"
                                disable-transitions>{{formatRemark(scope.row.remark)}}</el-tag>
                    </template>
            </el-table-column>
        </el-table>
        <div class="pageinfo">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-sizes="[1000]"
                    :page-size="pageSize"
                    layout="sizes, prev, pager, next"
                    :total="total">
            </el-pagination>
        </div>
        <el-divider><i class="el-icon-cpu"></i></el-divider>
    </div>
</template>

<script>
import qq_icon from "@/assets/qq_icon.png"
import telegram_icon from "@/assets/telegram_icon.png"
export default {
    name: "vir20bf",
    data(){
        return {
            currentPage: 1,
            pageSize: 1000,
            total: 200,
            loading: true,
            tableData: [],
            qq_icon: qq_icon,
            telegram_icon: telegram_icon,
        }
    },
    created() {
        this.getVirMach20BFData(1, 1000)
    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            return 'active-other-row'
        },
        cpuFormatter(row, column) {
            return row.cpu + ' vCPU'
        },
        armFormatter(row, column) {
            return row.ram + ' MB'
        },
        diskFormatter(row, column) {
            return row.hdd + ' GB'
        },
        ipFormatter(row, column) {
            return row.ips + ' IPv4'
        },
        priceFormatter(row, column) {
            return '$'+row.price
        },
        epriceFormatter(row, column) {
            return '$'+row.eprice
        },
        bwFormatter(row, column) {
            return row.bw + 'GB/mo'
        },
        discountFormatter(row, column) {
            if( row.estimate > 0 ){
                return row.estimate + '%'
            }else{
                return '-'
            }
        },
        formatRemark(remark) {
            switch(remark){
                case 'best':
                    return '惊人折扣，快抢！'
                case 'great':
                    return '绝对超值！'
                case 'good':
                    return '超值！'
                case 'average':
                    return '还行。'
                case 'cheap':
                    return '便宜货。'
                default:
                    return '一般般的啦！'
            }
        },
        getVirMach20BFData(pageNo, pageSize){
            const _this = this
            _this.loading = true
            let params = {"pageNo":pageNo,"pageSize":pageSize}
            axios.post('https://backend.stock.hostmonit.com/virmach_monitor/findAllVirMach20BF', params).then(function (resp) {
                _this.tableData = resp.data.content
                _this.total = resp.data.totalElements
                _this.loading = false
            })
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.getVirMach20BFData(1, this.pageSize)
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getVirMach20BFData(this.currentPage, this.pageSize)
        }
    }
}
</script>
<style type='text/css'>
    .el-table .active-other-row:hover>td {
	    background-color: #F0F9EB !important;
    }
</style>
<style scoped>
    .pageinfo {
        padding-top: 20px;
        padding-bottom: 20px;
    }
</style>