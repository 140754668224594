<template>
    <div>
        <p><span style="color: green; margin-right: 20px;">主机监控补货通知:</span><a target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=qbDlO4xz46K2vKG0iLgulJgcPRMyh6AN&jump_from=webapi"><img border="0" style= "margin-bottom: -5px" :src="qq_icon"  title="780813483"/>780813483</a>｜<a href="https://t.me/hostmonit" target="_blank"><img style= "margin-bottom: -5px;" title="@hostmonit" :src="telegram_icon"/></a><a href="https://t.me/hostmonit" target="_blank"> @hostmonit</a></p>
        <el-table
                :data="tableData"
                :row-class-name="tableRowClassName"
                v-loading="loading"
                style="width: 81%; margin: 0 auto"
        >
            <el-table-column
                    prop="title"
                    label="Title"
                    sortable
                    width="220"
                    :formatter="titleFormatter">
                    <template slot-scope="scope">
                        <el-badge v-if='scope.row.level > 1'
                            value="hot" class="item">
                            {{ scope.row.title }}
                        </el-badge>
                        <el-badge v-if='scope.row.level <= 1'
                            value="hot" class="item" hidden>
                            {{ scope.row.title }}
                        </el-badge>
                    </template>
            </el-table-column>
            <el-table-column
                    prop="cpu"
                    label="CPU"
                    sortable
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="ram"
                    label="RAM"
                    sortable
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="disk"
                    label="Disk"
                    sortable
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="ip"
                    label="IP"
                    sortable
                    width="90">
            </el-table-column>
            <el-table-column
                    prop="location"
                    label="Location"
                    sortable
                    width="120">
            </el-table-column>
            <el-table-column
                    prop="bandwidth"
                    label="Bandwidth"
                    sortable
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="price"
                    label="Price"
                    sortable
                    width="150"
            >
            </el-table-column>
            <el-table-column
                    prop="promoCode"
                    label="Promo"
                    sortable
                    width="170"
            >
            <template slot-scope="scope">
                <el-tag type="warning" 
                v-if='scope.row.promoCode !== "" && scope.row.promoCode !== null && scope.row.promoCode !== undefined' 
                style="cursor: pointer"
                @click="copyUrl(scope.row.promoCode)">
                    {{ scope.row.promoCode }}
                </el-tag>
            </template>
            </el-table-column>
            <el-table-column
                    prop="monitorStatus"
                    label="Available"
                    :sort-by="['status', 'id']"
                    sortable
                    width="110">
                    <template slot-scope="scope">
                        <el-tag
                                :type="scope.row.monitorStatus >= 1 ? 'success' : 'info'"
                                disable-transitions>{{formatStatus(scope.row.monitorStatus)}}</el-tag>
                    </template>
            </el-table-column>
            <el-table-column label="Operate" width="100">
                <template slot-scope="scope">
                    <el-button v-if='scope.row.monitorStatus >= 1' size="mini" type="success"  @click = "buy(scope.row.productUrl)">Buy</el-button>
                    <el-button v-else-if='scope.row.monitorStatus <= 0' size="mini" type="info"  @click = "open()">Monit</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pageinfo">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-sizes="[10, 50, 100, 200]"
                    :page-size="pageSize"
                    layout="sizes, prev, pager, next"
                    :total="total">
            </el-pagination>
        </div>
        <el-divider><i class="el-icon-cpu"></i></el-divider>
    </div>
</template>

<script>
import qq_icon from "@/assets/qq_icon.png"
import telegram_icon from "@/assets/telegram_icon.png"
    export default {
        name: "virmachSpecials",
        data(){
            return {
                currentPage: 1,
                pageSize: 10,
                total: 200,
                loading: true,
                tableData: [],
                qq_icon: qq_icon,
                telegram_icon: telegram_icon,
            }
        },
        created() {
            this.getVirmachSpecialsData(1, 10)
            this.dataRefreh()
        },
        methods: {
            tableRowClassName({ row, rowIndex }) {
                if (row.monitorStatus >= 1) {
                    return 'active-row'
                }
                return 'active-other-row'
            },
            buy(index) {
                window.open(index,'_blank')
            },
            formatStatus(status) {
                if(status >= 1){
                    return "YES"
                }else{
                    return "NO"
                }
            },
            titleFormatter(row, column){
                // let reg = new RegExp('\\','g')
                // let newTitle = JSON.stringify(row.title).replace(reg,'1');
                return row.title.replace('\\','')
            },
            getVirmachSpecialsData(pageNo, pageSize){
                const _this = this
                _this.loading = true
                let params = {"pageNo":pageNo,"pageSize":pageSize,"gid":"29", "gid2": "33"};
                axios.post('https://backend.stock.hostmonit.com/virmach/findMonitorLess', params).then(function (resp) {
                    _this.tableData = resp.data.content
                    _this.total = resp.data.totalElements
                    _this.loading = false
                })
            },
            handleSizeChange(val) {
                this.pageSize = val
                this.getVirmachSpecialsData(1, this.pageSize)
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.getVirmachSpecialsData(this.currentPage, this.pageSize)
            },
            open() {
                this.$alert('全球主机监控、补货通知QQ群(全体禁言)：<a target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=qbDlO4xz46K2vKG0iLgulJgcPRMyh6AN&jump_from=webapi">780813483</a></br>Telegram: <a target="_blank" href="https://t.me/hostmonit">@hostmonit</a>', '实时通知', {
                    confirmButtonText: '确定',
                    dangerouslyUseHTMLString:true
                    // callback: action => {
                    //     this.$message({
                    //         type: 'info',
                    //         message: `action: ${ action }`
                    //     });
                    // }
                },);
            },
            // 定时刷新数据函数
            dataRefreh() {
                // 计时器正在进行中，退出函数
                if (this.intervalId != null) {
                return;
                }
                // 计时器为空，操作
                this.intervalId = setInterval(() => {
                console.log("刷新" + new Date());
                this.getVirmachSpecialsData(this.currentPage, this.pageSize); //加载数据函数
                }, 60000);
            }, 
            // 停止定时器
            clear() {
                clearInterval(this.intervalId); //清除计时器
                this.intervalId = null; //设置为null
            },
            copyUrl(data){
                let url = data;
                let oInput = document.createElement('input');
                oInput.value = url;
                document.body.appendChild(oInput);
                oInput.select(); // 选择对象;
                console.log(oInput.value)
                document.execCommand("Copy"); // 执行浏览器复制命令
                this.$message({
                message: '复制成功',
                type: 'success'
                });
                oInput.remove()
            },
        },
        destroyed() {
            // 在页面销毁后，清除计时器
            this.clear();
        }
    }
</script>
<style type='text/css'>
    .el-table .active-row {
	    background-color: #F0F9EB !important;
    }

    .el-table .active-row:hover>td {
	    background-color: #b8e7a1 !important;
    }
</style>
<style scoped>
    .pageinfo {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .item {
        margin-top: 10px;
        margin-right: 32px;
    }
</style>