<template>
    <div class="card-container">
      <el-row :gutter="16">
        <el-col class="col-container" :xs="24" :sm="12" :md="8" :lg="6" v-for="(item, index) in cards" :key="item.id">
            <!-- <router-link :to="{ path: `/card/${card.title}` }"> -->
                <el-card :body-style="{ padding: '20px' }" shadow="hover">
                    <!-- <img :src="card.image" alt="" style="width: 100%"> -->
                    <div class="card-header">
                        <span style="font-weight: bold; font-size: 16px;">{{ item.title }}</span>
                        <span style="font-weight: bold; font-size: 14px;">{{ item.price }}</span>
                        <el-button class="button" text>Buy</el-button>
                    </div>
                    <div class="card-body">
                        <div style="text-align: center;  margin-top: 10px;">
                        {{ item.cpu }}
                        </div>
                        <div style="text-align: center; margin-top: 10px;">
                        {{ item.ram }}
                        </div>
                        <div style="text-align: center; margin-top: 10px;">
                        {{ item.disk }}
                        </div>
                        <div style="text-align: center; margin-top: 10px;">
                        {{ item.bandwidth }}
                        </div>
                        <div style="text-align: center; margin-top: 10px;">
                        {{ item.location }}
                        </div>
                        <div style="text-align: center; margin-top: 10px;">
                        {{ item.promoCode }}
                        </div>
                        <div style="text-align: center; margin-top: 10px;">
                        {{ item.ip }}
                        </div>
                    </div>
                </el-card>
            <!-- </router-link> -->
        </el-col>
      </el-row>
      <div v-if="!isLoadingMore" ref="loadMore" style="text-align: center; margin-top: 20px;">
        <el-button type="primary" @click="loadMore">加载更多</el-button>
      </div>
      <div v-if="isLoadingMore" style="text-align: center; margin-top: 20px;">
        已加载全部
      </div>
    </div>
</template>
  
<script>
import { Loading } from 'element-ui'
import axios from 'axios'

export default {
    name: 'monit',
    props: {
        inputData: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            cards:  [],
            currentPage: 1,
            pageSize: 12,
            total: 0,
            isLoadingMore: false,
            loading: null,
            monitorName: this.inputData
        }
    },
    watch: {
        inputData(newVal){
            this.monitorName = newVal;
        }
    },
    methods: {
        loadCards() {
            this.loading = Loading.service()
            let params = {"pageNo":this.currentPage,"pageSize":this.pageSize};
            axios.post('https://backend.stock.hostmonit.com/'+this.monitorName+'/findMonitorLess', params)
            .then(resp =>  {
                // console.log('res=>',resp);
                this.cards = [...this.cards, ...resp.data.content]
                this.total = resp.data.totalElements
                this.loading.close()
            })
            .catch(error => {
                console.error(error)
                this.loading.close()
            })
        },
        loadMore() {
            if (this.isLoadingMore) return
            if (this.currentPage * this.pageSize >= this.total) return
            this.isLoadingMore = true
            this.currentPage++
            this.loadCards()
        },
        handleScroll() {
            if (!this.$refs.loadMore) return
            const bottom = this.$refs.loadMore.getBoundingClientRect().bottom
            const windowHeight = window.innerHeight
            if (bottom <= windowHeight) {
                this.loadMore()
            }
        }
    },
    mounted() {
        this.loadCards()
        window.addEventListener('scroll', this.handleScroll)
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    }
}
</script>

<style>
    .card-container {
        margin: 0 auto;
        max-width: 1600px;
        padding: 20px;
    }

    .col-container {
        padding-bottom: 8px;
    }

    .card-header {
        height: 120px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .card-body {
        height: 200px;
    }

</style>
    