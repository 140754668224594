<template>
  <div id="app">
    <el-menu id="nav" :default-active="activeIndex" class="el-menu-demo" active-text-color="#42b983" :collapse-transition="true" mode="horizontal" :router="true">
      <el-menu-item index="/home">Home</el-menu-item>
      <el-submenu index="/">
        <template slot="title">Monitor</template>
        <el-menu-item index="/">ToSon</el-menu-item>
        <el-menu-item index="/bandwagonhost">BandwagonHost</el-menu-item>
        <el-menu-item index="/xtom">xTom</el-menu-item>
        <el-menu-item index="/dmit">DMIT</el-menu-item>
        <el-menu-item index="/rackNerd">RackNerd</el-menu-item>
        <el-menu-item index="/alphaVps">AlphaVps</el-menu-item>
        <!-- <el-menu-item index="/rcp">RCP</el-menu-item> -->
        <el-submenu index="/spartanhostPKS">
          <template slot="title">SpartanHost</template>
          <el-menu-item index="/spartanhostSDS">Seattle Dedicated Servers</el-menu-item>
          <el-menu-item index="/spartanhostPKS">SSD Premium KVM VPS - Seattle</el-menu-item>
          <el-menu-item index="/spartanhostE3S">HDD E3 KVM VPS - Seattle</el-menu-item>
          <el-menu-item index="/spartanhostE5S">SSD E5 KVM VPS - Seattle</el-menu-item>
        </el-submenu>
        <el-submenu index="/cloudcone">
          <template slot="title">CloudCone</template>
          <el-menu-item index="/cloudcone">CloudCone Compute(SC2)</el-menu-item>
          <el-menu-item index="/cloudconeVps">CloudCone VPS</el-menu-item>
        </el-submenu>
        <el-submenu index="/buyVM">
          <template slot="title">BuyVM</template>
          <el-menu-item index="/buyvmLV">Las Vegas - KVM & Storage</el-menu-item>
          <el-menu-item index="/buyvmLU">Luxembourg - KVM & Storage</el-menu-item>
          <el-menu-item index="/buyvmNY">New York - KVM & Storage</el-menu-item>
          <el-menu-item index="/buyvmMIA">Miami - KVM & Storage</el-menu-item>
        </el-submenu>
        <el-submenu index="/virSO">
          <template slot="title">VirMach</template>
          <el-menu-item index="/virSO">Virmach Special Offers</el-menu-item>
          <el-menu-item index="/vir18bf">Virmach 2018 Black Friday</el-menu-item>
          <el-menu-item index="/vir19bf">Virmach 2019 Black Friday</el-menu-item>
          <el-menu-item index="/vir20bf">Virmach 2020 Black Friday</el-menu-item>
          <el-menu-item index="/vir21bf">Virmach 2021 Black Friday</el-menu-item>
          <el-menu-item index="/vir22bf">Virmach 2022 Black Friday</el-menu-item>
        </el-submenu>
      </el-submenu>
      <el-submenu index="/CloudFlareYes">
        <template slot="title">CFYes</template>
        <el-menu-item index="/CloudFlareYes">IPv4</el-menu-item>
        <el-menu-item index="/CloudFlareYesV6">IPv6</el-menu-item>
      </el-submenu>
      <el-menu-item index="/about">About</el-menu-item>
    </el-menu>
    
    <router-view/>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  font-weight: bold;
  padding-left: 9%;
}


#nav a {
  font-weight: bold;
  color: #2c3e50;
}

</style>
<script>
export default {
  data(){
    return {
        activeIndex: this.$route.path,
    }
  },
  methods: {
  }
}
</script>
