import { render, staticRenderFns } from "./spartanhostE5S.vue?vue&type=template&id=2a370438&scoped=true&"
import script from "./spartanhostE5S.vue?vue&type=script&lang=js&"
export * from "./spartanhostE5S.vue?vue&type=script&lang=js&"
import style0 from "./spartanhostE5S.vue?vue&type=style&index=0&id=2a370438&prod&lang=css&"
import style1 from "./spartanhostE5S.vue?vue&type=style&index=1&id=2a370438&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a370438",
  null
  
)

export default component.exports